import React from 'react'
import '../../stylings/admissionNotice.css'

const AdmissionNotice = () => {
  return (
    <div className="admission-notice notice-header">
            <p>
                ADMISSION OPEN FOR THE SESSION 2024-2025. For more details contact at
                <span>vjconvent2004@gmail.com</span>
            </p>
        </div>
  )
}

export default AdmissionNotice
