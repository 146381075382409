import { createSlice } from "@reduxjs/toolkit";


export const photoGallerySlice = createSlice({
    name: 'photoGallery',
    initialState: {
        photoGallery: []
    },
    reducers: {
        setPhotoGallery: (state, action) => {
            state.photoGallery = action.payload;
        }
    },
});

export const {setPhotoGallery} = photoGallerySlice.actions;