import React from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';


const NormalNotice = ({ loadNotice }) => {

    const { notices } = useSelector((state) => state.notices)

    let formattedUpdateDate

    return (
        <div className='notice-slider'>
            <div className="notice-slider-header">
                <div className="notice-heading-wrapper">
                    <div className="notice-slider-icon">
                        <i className="fa-solid fa-scroll" > </i>
                    </div> <div className="notice-heading">
                        <h3> NOTICES </h3>
                    </div>
                </div>
                <div className="btn-container notice-btn">
                    <Link to='/noticeall' > VIEW ALL </Link>
                </div>
            </div>
            {
                !loadNotice ? <h3 className='text-center mt-2' > No Notice To Display </h3> :
                    <Swiper
                        direction='vertical'
                        slidesPerView={3}
                        spaceBetween={50}
                        loopAddBlankSlides={true}
                        loop={true}
                        breakpoints={
                            {
                                992: {
                                    slidesPerView: 4,
                                    spaceBetween: 0
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 130
                                }
                            }
                        }
                        autoplay={
                            {
                                delay: 2000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true
                            }
                        }
                        modules={
                            [Autoplay, Pagination, Navigation]}
                        className="mySwiper"


                    >
                        {
                            notices.map((notice, index) => index < 7 && (
                                <SwiperSlide key={notice.id}>
                                    <div className="list-rows">
                                        <div className="date-col">
                                            <span > {notice.publishedago} </span>
                                        </div>
                                        <div className="content-col">
                                            <div className="notice-content-header">
                                                <div className="notice-subject-header">
                                                    <h6> {notice.subject.substring(0, 40)} </h6>
                                                </div>
                                                <i className="fa-solid fa-scroll" ></i>

                                            </div>
                                            <div className="published-col">
                                                <span> Published on: {(
                                                    formattedUpdateDate = moment(notice.publisheddate, 'DD-MMM-YYYY hh:mm A').format('DD-MMM-YYYY')
                                                )} </span>
                                            </div>
                                            <div className="notice-btn">
                                                <Link to={`/notice/${notice.id}`} > View More </Link>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>}
        </div>
    )
}

export default NormalNotice