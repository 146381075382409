import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import "../stylings/syllabus.css"
import { useDispatch } from 'react-redux'
import { hideLoading, showLoading } from '../redux store/loader'

const Syllabus = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const [syllabus, setSyllabus] = useState([])
    const [className, setClassName] = useState("")
    const getSyllabus = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SYLLABUS_API)
            if (response.data) {
                const sortData = response.data.sort((a, b) => a.classid - b.classid)
                setSyllabus(sortData)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getSyllabus()
    }, [])

    const filterRepeatedValues = (syllabus) => {
        const filterClassName = [];
        return syllabus.filter(syllabusItem => {
            if (!filterClassName.includes(syllabusItem.classname)) {
                filterClassName.push(syllabusItem.classname);
                return true;
            }
            return false;
        });
    };

    const filterClassData = syllabus.filter(syllabusItem => syllabusItem.classname === className);
    return (


        <Layout>
            <div className="syllabus container text-justify">
                <div className="select-box text-center">
                    <label>Select Class For Syllabus</label>
                    <select onChange={(e) => setClassName(e.target.value)}>
                        <option value="">Please Select The Class</option>
                        {
                            filterRepeatedValues(syllabus).map((syllabusItem, index) => (
                                <option key={index}>{syllabusItem.classname}</option>
                            ))
                        }
                    </select>
                </div>
                {className && <>
                    <div className="public-disclosure-header">
                        <h3 className='syllabus-heading internal'>syllabus for {`${className}`}</h3>
                    </div>
                    {filterClassData.map((classDataItem) => (
                        <div className="public-disclosure syllabus-table-container container" key={classDataItem.id}>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th scope="row">name</th>
                                            <td>{classDataItem.displayname}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">date</th>
                                            <td>{classDataItem.publisheddate}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">attachments</th>
                                            <td>
                                                {classDataItem.attachments.map((classDataAttachments, index) => (
                                                    <Link to={classDataAttachments.path} className='d-block mt-2' target='_blank' key={classDataAttachments.id}>{`Attachment ${index + 1}`}<i className="fa-solid fa-download"></i></Link>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </>}
            </div>
        </Layout>
    )
}

export default Syllabus
