import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import ReactAudioPlayer from 'react-audio-player'
import { hideLoading, showLoading } from '../redux store/loader'
import moment from 'moment'

const AudioNoticesAll = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const { audioNotices } = useSelector((state) => state.audioNotices)
    let formattedUpdateDate

    return (
        <Layout>
            <div className="public-disclosure container">
                <div className="public-disclosure-header timetable-subheading">
                    <h3 className='syllabus-heading'>All Audio Notices</h3>
                </div>
                {
                    !audioNotices ? <div className="error-heading">no notice to display</div> : <>

                        {audioNotices.map((noticesItem) => (
                            <div className="audio-wrapper" key={noticesItem.id}>
                                <div className="audio-header-container">
                                    <div className="title">
                                        <span>Title: </span>{noticesItem.subject}
                                    </div>
                                    <div className="ago">
                                        <span>Published: </span> {(<>

                                            {formattedUpdateDate = moment(noticesItem.publisheddate, 'DD-MMM-YYYY hh:mm A').format('DD-MMM-YYYY')} <span className='text-dark'>{`(${noticesItem.publishedago})`}</span>
                                        </>)}
                                    </div>
                                </div>
                                <div className="body-container">
                                    {noticesItem.attachments.map((attachmentItem) => (
                                        <ReactAudioPlayer
                                            key={attachmentItem.id}
                                            src={attachmentItem.path}
                                            controls
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>
        </Layout>
    )
}

export default AudioNoticesAll
