import { createSlice } from "@reduxjs/toolkit";


export const noticeSlice = createSlice({
    name: 'notices',
    initialState: {
        notices: []
    },
    reducers: {
        setNotices: (state, action) => {
            state.notices = action.payload;
        }
    },
});

export const {setNotices} = noticeSlice.actions;