import React, { useEffect, useState } from 'react'
import "../../stylings/gallery.css"
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Gallery = () => {

  const { photoGallery } = useSelector((state) => state.photoGallery)

  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [imagePath, setImagePath] = useState([])

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(imagePath.length - 1)
      : setSlideNumber(slideNumber - 1)
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === imagePath.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1)
  }

  useEffect(() => {
    photoGallery.map((galleryItem, galleryItemIndex) => galleryItemIndex < 5 && (
      galleryItem.attachments.map((galleryAttachment, galleryAttachmentIndex) => galleryAttachmentIndex < 1 && (
        setImagePath(oldPath => [...oldPath, galleryAttachment.path])
      ))
    ))
  }, [photoGallery])

  return (
    <div className="photo-gallery-section">

      {openModal &&
        <div className='sliderWrap'>
          <i className="fa-solid fa-xmark btnClose" onClick={handleCloseModal}></i>
          <i className="fa-solid fa-chevron-left btnPrev" onClick={prevSlide}></i>
          <i className="fa-solid fa-chevron-right btnNext" onClick={nextSlide}></i>
          <div className='fullScreenImage'>
            <img src={imagePath[slideNumber]} alt='' />
          </div>
        </div>
      }

      <div className="topper container">

        <div className="topper-awards-heading">
          <div className="topper-awards-icon">
            <i className="fa-solid fa-images"></i>
          </div>
          <h3>PHOTO GALLERY</h3>
        </div>

        <div className="gallery">
          {photoGallery.map((galleryItem, galleryItemIndex) => galleryItemIndex < 9 && (
            <div className={`gallery-item gallery-item-${galleryItemIndex + 1}`} key={galleryItem.id}>
              {galleryItem.attachments.map((galleryAttachment, galleryAttachmentIndex) => galleryAttachmentIndex < 1 && (
                <Link to={undefined} className="gallery-link" onClick={() => handleOpenModal(galleryItemIndex)}>
                  <img src={galleryAttachment.path} className="gallery-image" />
                </Link>
              ))}
            </div>
          ))}
        </div>
        <div className="btn-container">
          <Link to='/galleryall'><button>VIEW ALL</button></Link>
        </div>
      </div>
    </div>
  )
}

export default Gallery
