import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap';
import '../../stylings/carouselSection.css'
import building from '../../assest/building.jpg'
import building1 from '../../assest/building1.jpg'
import activity from '../../assest/activity.jpg'

const CarouselSection = () => {
  // Sample data for carousel items
  const carouselItems = [
    {
      id: 1,
      // title: 'Slide 1',
      content: 'Classroom Image of Vidya Jain Convent School',
      image: activity,
    },
    {
      id: 2,
      // title: 'Slide 2',
      content: 'Building of Vidya Jain Convent School',
      image: building1,
    },
    {
      id: 3,
      // title: 'Slide 3',
      content: 'Building of Vidya Jain Convent School',
      image: building,
    }
  ];

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="carousel-container">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {carouselItems.map((item) => (
          <Carousel.Item key={item.id}>
            <img
              className="d-block w-100"
              src={item.image}
              alt={`Slide ${item.id}`}
            />
            <Carousel.Caption>
              <p>{item.content}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselSection
