import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';
import '../stylings/messagesPage.css'

const ManagerMessage = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    return (
        <Layout>
            <div className="message-container container">
                <div className="message-wrapper">
                    <div className="message-wrapper-header">
                        <h3>From The Desk Of Manager</h3>
                    </div>
                    <div className="body-wrapper">
                        {/* <div className="img-container">
                            <img src={chairmanImage} alt="chairman" />
                        </div> */}
                        <div className="content">
                            {/* <p className="quote">
                                "Failures comes only, when we Forget our ideals, objectives and principles - JAWAHARLAL NEHRU"
                            </p> */}
                            <p>
                                It gives me immense joy to see that our school has, over the years, acquired the true mark of excellence as a world class school, not only in the field of academic Learning, but in all areas of educational activity. Youth resonates with infinite potential. Youth is our future. I believe that the students' accomplishments will exceed our wildest dreams. "The school is the constant and continuous process of educating and enhancing the students' personality and knowledge, infusing them with a greater degree of confidence and poise than what their predecessors gained from the school. <span>M. R. Bharti Model Sr. Sec. School</span>, Mundka also stands as a proud witness to their struggle for an identity. Children have an impressionable mind full of curiosity and sensitivity, always seeking avenues to express their ideas. I have been striving to sharpen the quality of that consciousness and urge in them to excel by providing opportunities through their school, for tapping this potential.
                            </p>
                            <div className="signature">
                                <p className="name">
                                    Mr. Ranvir Singh
                                </p>
                                <p className="designation">
                                    Manager, M.R. Bharti Model Sr. Sec. School
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ManagerMessage
