import React from 'react'
import "../../stylings/topper.css"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

//Toppers Photo

import firstClass from '../../assest/topper photos/pratyusha.jpeg'
import secondClass from '../../assest/topper photos/devesh.jpeg'
import thirdClass from '../../assest/topper photos/chirag.jpeg'
import fourthClass from '../../assest/topper photos/ishika.jpeg'
import fifthClass from '../../assest/topper photos/pahal.jpeg'
import sixthClass from '../../assest/topper photos/navneet.jpeg'
import sevenClass from '../../assest/topper photos/harshit.jpeg'
import eightClass from '../../assest/topper photos/prachi.jpeg'

const Topper = () => {
    return (
        <div className="topper container">
            <div>
                <div className="topper-awards-heading">
                    <div className="topper-awards-icon">
                        <i className="fa-solid fa-trophy"></i>
                    </div>
                    <h3>ACADEMIC EXCELLENCE (2023-24)</h3>
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 70
                        }
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={firstClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Pratyusha</p>
                                {/* <p className="position"><strong>Position:</strong> 1st Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 95%</p> */}
                                <p className="class"><strong>Class:</strong> 1<sup>th</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={secondClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Devesh Dabas</p>
                                {/* <p className="position"><strong>Position:</strong> 1st Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 87.3%</p> */}
                                <p className="class"><strong>Class:</strong> 2<sup>nd</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={thirdClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Chirag</p>
                                {/* <p className="position"><strong>Position:</strong> 3rd Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 86.8%</p> */}
                                <p className="class"><strong>Class:</strong> 3<sup>rd</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={fourthClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Ishika Dabas</p>
                                {/* <p className="position"><strong>Position:</strong> 4th Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 84%</p> */}
                                <p className="class"><strong>Class:</strong> 4<sup>th</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={fifthClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Pahal</p>
                                {/* <p className="position"><strong>Position:</strong> 5th Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 77.6%</p> */}
                                <p className="class"><strong>Class:</strong> 5<sup>th</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={sixthClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Navneet Verma</p>
                                {/* <p className="position"><strong>Position:</strong> 6th Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 76.1%</p> */}
                                <p className="class"><strong>Class:</strong> 6<sup>th</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={sevenClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Harshit</p>
                                {/* <p className="position"><strong>Position:</strong> 7th Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 76.1%</p> */}
                                <p className="class"><strong>Class:</strong> 7<sup>th</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card" >
                            <div className="img-container">
                                <img src={eightClass} className="card-img-top" alt="1" />
                            </div>
                            <div className="card-body">
                                <p className='student-name'><strong>Name:</strong> Prachi Singh</p>
                                {/* <p className="position"><strong>Position:</strong> 7th Position</p> */}
                                {/* <p className='marks'><strong>Percentage:</strong> 76.1%</p> */}
                                <p className="class"><strong>Class:</strong> 8<sup>th</sup></p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Topper
