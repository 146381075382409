import React from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const SchoolAwardListCarousel = ({schoolAchievements}) => {

    return (
        <div>
            <Swiper
                direction='vertical'
                slidesPerView={4}
                loop={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {schoolAchievements.map((achievement) => (
                    <SwiperSlide key={achievement.id}>
                        <div className="wrapper">
                            <div className="date-container">
                               <span>{achievement.awardDate}</span>
                            </div>
                            <div className="content-wrapper">
                                <p>{achievement.awardTitle}</p>
                                <a href={achievement.path}><p>View More</p></a>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default SchoolAwardListCarousel
