import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import "../stylings/noticeAll.css"
import { Link } from 'react-router-dom'
import { hideLoading, showLoading } from '../redux store/loader'
import moment from 'moment'

const NormalNoticeAll = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const { notices } = useSelector((state) => state.notices)
    let formattedUpdateDate

    return (
        <Layout>
            <div className="public-disclosure normal-notice-all container">
                <div className="public-disclosure-header timetable-subheading">
                    <h3 className='syllabus-heading'>All Notices</h3>
                </div>
                {
                    !notices ? <div className="error-heading">no notice to display</div> : <>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Subject</th>
                                        <th>Published Date</th>
                                        <th>More Details</th>
                                    </tr>
                                    {notices.map((noticesItem, index) => (
                                        <tr key={noticesItem.id}>
                                            <td>{index + 1}</td>
                                            <td>{noticesItem.subject}</td>
                                            <td>{(
                                                    formattedUpdateDate = moment(noticesItem.publisheddate, 'DD-MMM-YYYY hh:mm A').format('DD-MMM-YYYY')
                                                )}</td>
                                            <td><Link to={`/notice/${noticesItem.id}`} > View More </Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>
        </Layout>
    )
}

export default NormalNoticeAll
