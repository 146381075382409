import React from 'react'
import '../stylings/navbar.css'
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top2">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="schoolName-container d-lg-none">
                    Vidya Jain Convent School
                </div>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                About Us
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/about-school">About School</Link></li>
                                <li><Link className="dropdown-item" to="/public-disclosure">Mandatory Public Disclosure</Link></li>
                                <li><Link className="dropdown-item" to="/cbse-mandatory-public-disclosure">CBSE Mandatory Disclosure</Link></li>
                            </ul>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Messages
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/chairman-message">Chairman Message</Link></li>
                                <li><Link className="dropdown-item" to="/principal-message">Principal Message</Link></li>
                                <li><Link className="dropdown-item" to="/manager-message">Manager Message</Link></li>
                            </ul>
                        </li> */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Academics
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="#">Faculty</Link></li>
                                <li><Link className="dropdown-item" to="#">Fee Structure</Link></li>
                                <li><Link className="dropdown-item" to="#">Curriculum</Link></li>
                                <li><Link className="dropdown-item" to="#">PTM</Link></li>
                                <li><Link className="dropdown-item" to="#">Activities</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Student
                            </Link>
                            <ul className="dropdown-menu">
                                {/* <li><Link className="dropdown-item" to="#">Assignments</Link></li> */}
                                <li><Link className="dropdown-item" to="/syllabus">Syllabus</Link></li>
                                <li><Link className="dropdown-item" to="/time-table">Time Table</Link></li>
                                <li><Link className="dropdown-item" to="/noticeall">Notice</Link></li>
                                <li><Link className="dropdown-item" to="/audionoticeall">Audio Announcement</Link></li>
                                <li><Link className="dropdown-item" to="/academic-calender">Academic Calender</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="#">Achievements</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Gallery
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="/galleryall">Photo Gallery</Link></li>
                                <li><Link className="dropdown-item" to="/videoall">Video Gallery</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>

        </nav>
    )
}

export default Navbar
