import React from 'react'
import gsap from 'gsap'
import logoLoader from '../assest/logoLoader.png'
import { useLayoutEffect, useRef } from 'react'
import SplitType from 'split-type'

const Loader = () => {

    const comp = useRef(null)

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline()
            t1.from("#intro-slider", {
                xPercent: "+25",
                duration: 0.4,
                delay: 0.3,
            })
                .to("#loaderScreen", {
                    opacity: 0,
                    delay: 1
                })
        }, comp)

        const splitText = new SplitType('#schoolName')

        gsap.to('.char', {
            y: 0,
            stagger: 0.1,
            delay: 0,
            duration: 0
        })

        return () => ctx.revert()
    }, [])

    return (
        <div ref={comp}>
            <div className='loader-fullScreen d-flex' id='loaderScreen'>
                <div className='loader-container' id="intro-slider">
                    <div className="img-container" id="title-1">
                        <img src={logoLoader} alt="" />
                    </div>
                    <div className="loaderText-container" id="title-2">
                        <h3 id='schoolName'>VJ CONVENT SCHOOL</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader
