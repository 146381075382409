import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import "../stylings/login.css"
import myCampusGif from "../assest/myCampusGif.gif"
import { Link } from 'react-router-dom'
import myCampusFullLogo from "../assest/myCampusFullLogo.png"
import playstore from "../assest/playstore.png"
import { useDispatch } from 'react-redux'
import { hideLoading, showLoading } from '../redux store/loader'



const Login = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    return (
        <Layout>
            <div className="login container">
                <div className="row justify-content-center">
                    {/* <div className="col-md-6 text-center">
                        <h2 className="heading-section">Login #07</h2>
                    </div> */}
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="wrap d-md-flex">
                            <div className="brandingBox text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
                                <div className="text">
                                    <h5>WELCOME TO
                                        THE MOST TRUSTED
                                        SCHOOL MANAGEMENT SYSTEM
                                    </h5>
                                    <div className="phone-gif-container">
                                        <img src={myCampusGif} alt="" />
                                    </div>
                                    <div className="social">
                                        <div className='social-wrapper'>
                                            <p>Download <span><img src={myCampusFullLogo} alt="" /></span> App</p>
                                            <div className="playstore-container">
                                                <Link to="https://play.google.com/store/apps/details?id=com.mycampus.smsapp" target="_blank" tabindex="0">
                                                    <img src={playstore} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="login-wrap p-4 p-lg-5">
                                <div className="d-flex">
                                    <div className="w-100 form-header">
                                        <h3 className="mb-4">Sign In</h3>
                                    </div>
                                </div>
                                <form action="#" className="signin-form">
                                    <div className="form-group mb-3">
                                        <label className="label" for="name">Username</label>
                                        <input type="text" className="form-control" placeholder="Username" required="" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="label" for="password">Password</label>
                                        <input type="password" className="form-control" placeholder="Password" required="" />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="form-control btn btn-primary submit px-3">Sign In</button>
                                    </div>
                                    <div className="form-group d-flex">
                                        <div className="text-left">
                                            <input type="checkbox" id="rememberMe" name="rememberMe" value="Yes" className='me-2 checkbox' />
                                            <label for="rememberMe">Remember Me</label>
                                        </div>
                                        <div className="text-md-right">
                                            <Link to="#">Forgot Password</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Login
