import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';
import "../stylings/singlenotice.css"

const SingleNotice = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const param = useParams();
    const noticeId = parseInt(param.noticeId)
    const { notices } = useSelector((state) => state.notices)

    const filterNoticesData = notices.filter(noticeItem => noticeItem.id === noticeId);

    return (
        <Layout >
            <div className="singlenotice container" >
                {filterNoticesData.map((singlenotice) => (
                    <div className="singlenotice-wrapper" key={singlenotice.id}>
                        <div className="singlenotice-header" >
                            <h3 > NOTICE <span>{`(${singlenotice.publishedago})`}</span> </h3>
                        </div>
                        <div className="header-wrapper">
                            <div className="title">Title: {singlenotice.subject} </div>
                            <div className="published">Published Date: {singlenotice.publisheddate} </div>
                        </div>
                        <div className="description-wrapper">
                            <div className="description mb-2">
                                <span className='me-1'> <strong> Description: </strong></span> {singlenotice.body}
                            </div> {singlenotice.attachments.map((attachmentItem, index) => ((attachmentItem &&
                                <>
                                    <div className="attachments" key={attachmentItem.id}>
                                        <div className="attachments-link mb-2">
                                            <Link to={attachmentItem.path} target="_blank" rel="noopener noreferrer" ><i class="fa-solid fa-paperclip me-2"></i>{`Notice_Attachments_${index + 1}`} </Link>
                                        </div>
                                    </div>
                                </>)
                            ))
                            }

                        </div>
                    </div>
                ))
                }
            </div>
        </Layout>
    )
}

export default SingleNotice