import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';
import "../stylings/publicDisclosure.css"
import buildingCertificate from '../assest/building_certificate.pdf'
import deed from '../assest/deed.pdf'
import educationalDepartmentFolder from '../assest/educational_department_folder.pdf'
import fireSafetyCertificate from '../assest/fire_safety_certificate.pdf'
import healthCertificate from '../assest/health_certificate.pdf'
import immaculateMarySocietyCertificate from '../assest/immaculate_mary_society.pdf'
import listOfInfrastructureCertificate from '../assest/list_of_infrastructure.pdf'
import listOfManagementCertificate from '../assest/list_of_managment.pdf'
import rainWaterHarvestingCertificate from '../assest/rain_water_harvesting.pdf'
import recognitionLetterCertificate from '../assest/recognition_letter.pdf'
import schoolHealthCertificate from '../assest/school_health_certificate.pdf'
import secondarySchoolCertificate from '../assest/secondary_school_certificate.pdf'
import structuralStabilityCertificate from '../assest/structure_stability_certificate-.pdf'
import roomdesription from '../assest/Room_description.pdf'
import { Link } from 'react-router-dom';
const PublicDisclosure = () => {

  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

  return (
    <Layout>
      <div className="public-disclosure container">
        <div className="public-disclosure-header">
          <h3>mandatory public disclosure</h3>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">name of the school</th>
                <td>Vidya Jain Convent School</td>
              </tr>
              <tr>
                <th scope="row">address</th>
                <td>Khasra No.- 142, Plot - 289 , Delhi - 110081</td>
              </tr>
              <tr>
                <th scope="row">principal name & qualification</th>
                <td>Mr. Naresh Kumar Jain</td>
              </tr>
              <tr>
                <th scope="row">school e-mail</th>
                <td className='email-td'>vjconvent2004@gmail.com</td>
              </tr>
              <tr>
                <th scope="row">contact details</th>
                <td>9999740333</td>
              </tr>
              <tr>
                <th scope="row">education department certificate</th>
                <td><Link to={`/${educationalDepartmentFolder}`} target="_blank" rel="noopener noreferrer"> education department certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">health certificate</th>
                <td><Link to={`/${healthCertificate}`} target="_blank" rel="noopener noreferrer"> health certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">immaculate mary society certificate</th>
                <td><Link to={`/${immaculateMarySocietyCertificate}`} target="_blank" rel="noopener noreferrer"> immaculate mary society certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">fire safety certificate</th>
                <td><Link to={`/${fireSafetyCertificate}`} target="_blank" rel="noopener noreferrer"> fire safety certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">rain water harvesting certificate</th>
                <td><Link to={`/${rainWaterHarvestingCertificate}`} target="_blank" rel="noopener noreferrer"> rain water harvesting certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">deed certificate</th>
                <td><Link to={`/${deed}`} target="_blank" rel="noopener noreferrer"> deed certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">infrastructure certificate</th>
                <td><Link to={`/${listOfInfrastructureCertificate}`} target="_blank" rel="noopener noreferrer"> infrastructure certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">room details info</th>
                <td><Link to={`/${roomdesription}`} target="_blank" rel="noopener noreferrer"> room details <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">management certificate</th>
                <td><Link to={`/${listOfManagementCertificate}`} target="_blank" rel="noopener noreferrer"> management certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">structure stability certificate</th>
                <td><Link to={`/${structuralStabilityCertificate}`} target="_blank" rel="noopener noreferrer"> structure stability certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">building certificate</th>
                <td><Link to={`/${buildingCertificate}`} target="_blank" rel="noopener noreferrer"> building certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">school health certificate</th>
                <td><Link to={`/${schoolHealthCertificate}`} target="_blank" rel="noopener noreferrer"> school health certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">recognition letter certificate</th>
                <td><Link to={`/${recognitionLetterCertificate}`} target="_blank" rel="noopener noreferrer"> recognition letter certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">secondary school certificate</th>
                <td><Link to={`/${secondarySchoolCertificate}`} target="_blank" rel="noopener noreferrer"> secondary school certificate <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">inspection video</th>
                <td><Link to={`https://www.youtube.com/watch?v=ZrZJAq8L8i0`} target="_blank" rel="noopener noreferrer"> inspection video <i className="fa-solid fa-download"></i></Link></td>
              </tr>
              <tr>
                <th scope="row">computer lab video</th>
                <td><Link to={`https://www.youtube.com/watch?v=dk69OnOBMLY`} target="_blank" rel="noopener noreferrer"> computer lab video <i className="fa-solid fa-download"></i></Link></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default PublicDisclosure
