import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';
import '../stylings/messagesPage.css'
import chairmanPhoto from "../assest/chairmanPhoto.jpg"

const ChairmanMessage = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    return (
        <Layout>
            <div className="message-container container">
                <div className="message-wrapper">
                    <div className="message-wrapper-header">
                        <h3>From The Desk Of Chairman</h3>
                    </div>
                    <div className="body-wrapper">
                        <div className="img-container">
                            <img src={chairmanPhoto} alt="chairman" />
                        </div>
                        <div className="content">
                            <p className="quote">
                                "Failures comes only, when we Forget our ideals, objectives and principles - JAWAHARLAL NEHRU"
                            </p>
                            <p>
                               <span>M. R. Bharti Model Sr. Sec. School</span> is one of the Premier Schools under the <span>Mangu Ram Educational & Social Welfare Society</span> in 2006 which has also founded other educational institutions for higher studies. <span>M. R. Bharti Model Sr. Sec. School</span> is the labour of love of its founder. The vision was to inculcate international quality education with Indian values and to provide affordable high-quality education to our citizens.
                            </p>
                            <div className="signature">
                                <p className="name">
                                    Mr. Naresh Kumar
                                </p>
                                <p className="designation">
                                    Chairman, M.R. Bharti Model Sr. Sec. School
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ChairmanMessage
