import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import '../../stylings/video.css'

const VideoGallery = () => {

    const { videoGallery } = useSelector((state) => state.videoGallery)

    const embedVideoUrl = (url) => {
        return url.replace("https://youtu.be", "https://www.youtube.com/embed");
    };


    return (
       
        <div className="video-gallery-section">
            <div className="topper container">
                <div className="topper-awards-heading">
                    <div className="topper-awards-icon">
                        <i className="fa-solid fa-images"></i>
                    </div>
                    <h3>VIDEO GALLERY</h3>
                </div>

                <div className="gallery">
                    {videoGallery.map((galleryItem, galleryItemIndex) => galleryItemIndex < 5 && (
                        <div className={`gallery-item`} key={galleryItem.id}>
                            {galleryItem.attachments.map((galleryAttachment, galleryAttachmentIndex) => galleryAttachmentIndex < 1 && (
                                <>
                                    <iframe key={galleryAttachment.id} src={embedVideoUrl(galleryAttachment.path)} frameBorder={0} allowFullScreen={true} allowtransparency="true">
                                    </iframe>
                                </>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="btn-container">
                    <Link to='/videoall'><button>VIEW ALL</button></Link>
                </div>
            </div>
        </div>

    )
}

export default VideoGallery
