import React from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const SchoolAwardImageCarousel = ({schoolAchievements}) => {

    return (
        <div>
            <Swiper
                slidesPerView={1}
                spaceBetween={20}
                loop={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {schoolAchievements.map((achievement) => (
                    <SwiperSlide key={achievement.id}>
                        <div className="image-container">
                            <img src={achievement.awardImageUrl} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default SchoolAwardImageCarousel
