import React from 'react'
import NormalNotice from './NormalNotice'
import AudioNotice from './AudioNotice'
import "../../stylings/notice.css"

const Notices = ({loadNotice, audioLoadNotice}) => {
    return (
        <div className='container notice-container'>
            <div className="row notices-row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <NormalNotice loadNotice={loadNotice}/>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <AudioNotice audioLoadNotice={audioLoadNotice} /> 
                </div>
            </div>
        </div>
    )
}

export default Notices
