import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {

    const { pathname } = useLocation();
    const [isVisible, setIsVisible] = useState(false)

    //function to go to Top of Screen
    const gotToTop = () => {
        setTimeout(() => {
            // window.scrollTo(0, 0);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    }

    // function to determine the height and render the scrollToTop button
    const listenToScroll = () => {

        let heightToVisible = 350;

        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (windowScroll > heightToVisible) {
            setIsVisible(true)
        }
        else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        gotToTop()
    }, [pathname])

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
    })

    return (
        <>
            {isVisible && (
                <div id='progressBar' onClick={gotToTop}>
                    <i id='progress-value' class="fa-solid fa-angle-up"></i>
                </div>
            )}
        </>
    )
}

export default ScrollToTop
