import { createSlice } from "@reduxjs/toolkit";


export const videoGallerySlice = createSlice({
    name: 'videoGallery',
    initialState: {
        videoGallery: []
    },
    reducers: {
        setVideoGallery: (state, action) => {
            state.videoGallery = action.payload;
        }
    },
});

export const {setVideoGallery} = videoGallerySlice.actions;