import React from 'react'
import '../../stylings/schoolAwards.css'
import SchoolAwardImageCarousel from './SchoolAwardImageCarousel'
import SchoolAwardListCarousel from './SchoolAwardListCarousel'
import preetDabas from '../../assest/school awards/preet-dabas.jpeg'
import pariSharma from '../../assest/school awards/pari-sharma.jpeg'
import nishikaSingh from '../../assest/school awards/nishika-singh.jpeg'

const SchoolAwards = () => {

    const schoolAchievements = [
        {
            id: 1,
            awardTitle: <span><strong>Sub Junior Category Bronze Prize</strong> By Preet Dabas</span>,
            awardDate: "28-OCT",
            awardImageUrl: preetDabas,
            path: "#"
        },
        {
            id: 2,
            awardTitle: <span><strong>Women's Under-15 One Day Trophy</strong> by Pari Sharma</span>,
            awardDate: "28-NOV",
            awardImageUrl: pariSharma,
            path: "#"
        },
        {
            id: 3,
            awardTitle: <span><strong>Women's Under-19 One Day Trophy</strong> by Nishika Singh</span>,
            awardDate: "28-DEC",
            awardImageUrl: nishikaSingh,
            path: "#"
        },
        {
            id: 4,
            awardTitle: "Certificate of Waste Management",
            awardDate: "28-FEB",
            awardImageUrl: preetDabas,
            path: "#"
        },
        {
            id: 5,
            awardTitle: "Certificate of Water Management",
            awardDate: "28-JAN",
            awardImageUrl: pariSharma,
            path: "#"
        },
    ]

    return (
        <div className='schoolAwards-container' id='schoolAwards'>
            <div className="container">
                <div className="row school-award">
                    <div className="school-awards-heading">
                        <div className="notice-slider-icon school-awards-icon">
                            <i className="fa-solid fa-medal"></i>
                        </div>
                        <h3>SCHOOL AWARDS</h3>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 imageSwiper-slider">
                        <SchoolAwardImageCarousel schoolAchievements={schoolAchievements} />
                    </div>
                    <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 detailSwiper-slider">
                        <SchoolAwardListCarousel schoolAchievements={schoolAchievements} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolAwards
