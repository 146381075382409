import React, { useEffect, useState } from 'react'
import "../stylings/academicCalender.css"
import axios from 'axios'
import Layout from '../components/Layout'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { hideLoading, showLoading } from '../redux store/loader'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

const AcademicCalender = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const [academicCalender, setAcademicCalender] = useState([])
    let date;
    let end;

    const getAcademicCalender = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_CALENDER_EVENT_API)
            if (response.data) {
                setAcademicCalender(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAcademicCalender()
    }, [])

    return (
        <Layout>
            <div className="academiccalender container my-2">
                <div className="row">
                    <div className="academiccalender-heading">
                        <h3>Academic Calender 2024</h3>
                    </div>
                    <div className="col-md-12">
                        <VerticalTimeline>
                            {academicCalender.map((scheduleItem) => {
                                return (
                                    <VerticalTimelineElement
                                        key={scheduleItem.id}
                                        contentArrowStyle={{ '--schedule-arrow-color': scheduleItem.eventtypecolor }}
                                        date={scheduleItem.singledayevent ? moment(scheduleItem.startdate).format('DD MMM YYYY (dddd)')
                                            :
                                            (
                                                date = moment(scheduleItem.startdate).format('DD MMM YYYY'),
                                                end = moment(scheduleItem.enddate).format('DD MMM YYYY'),
                                                `${date} - ${end}`
                                            )
                                        }
                                        dateClassName='date'
                                        iconStyle={{ backgroundColor: scheduleItem.eventtypecolor }}
                                    >
                                        <h3 className='vertical-timeline-element-title' style={{ color: scheduleItem.eventtypecolor }}>{scheduleItem.name}</h3>
                                        <p className="description" style={{ backgroundColor: scheduleItem.eventtypecolor, color: 'white' }}>
                                            <p>{scheduleItem.description}</p>
                                            <p>({scheduleItem.eventtypename})</p>
                                        </p>
                                    </VerticalTimelineElement>
                                )
                            })}
                        </VerticalTimeline>
                    </div>
                </div>
            </div >
        </Layout>
    )
}

export default AcademicCalender
