import React from 'react'
import "../stylings/bannerImage.css"
import bannerImage from "../assest/building.jpg"

const BannerImage = () => {
    return (
        <div className='banner'>
            <img src={bannerImage} alt="" />
        </div>
    )
}

export default BannerImage
