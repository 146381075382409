import React, { useEffect } from 'react'
import ReactAudioPlayer from 'react-audio-player';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { hideLoading, showLoading } from '../redux store/loader';

const SingleAudioNotice = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const param = useParams();
    const noticeId = parseInt(param.noticeId)
    const { audioNotices } = useSelector((state) => state.audioNotices)

    const filterNoticesData = audioNotices.filter(noticeItem => noticeItem.id === noticeId);


    return (
        <Layout>
            <div className="singlenotice container" >
                {filterNoticesData.map((singlenotice) => (
                    <div className="singlenotice-wrapper" key={singlenotice.id}>
                        <div className="singlenotice-header" >
                            <h3 > AUDIO NOTICE <span>{`(${singlenotice.publishedago})`}</span> </h3>
                        </div>
                        <div className="header-wrapper">
                            <div className="title">Title: {singlenotice.subject} </div>
                            <div className="published">Published Date: {singlenotice.publisheddate} </div>
                        </div>
                        <div className="description-wrapper">
                            <div className="description mb-2">
                                <span className='me-1'> <strong> Description: </strong></span> {singlenotice.body}
                            </div> 
                            {singlenotice.attachments.map((attachmentItem) => (
                                <div className="attachments" key={attachmentItem.id}>
                                    <ReactAudioPlayer
                                        key={attachmentItem.id}
                                        src={attachmentItem.path}
                                        autoPlay
                                        controls
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                ))
                }
            </div>
        </Layout>
    )
}

export default SingleAudioNotice
