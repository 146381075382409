import React from 'react'
import "../../stylings/facility.css"
import learn from '../../assest/gallery/learn.jpg'
import activity from '../../assest/gallery/activity.jpg'
import digitalClassroom from '../../assest/gallery/digital-classroom.jpg'
import personalDevelopment from '../../assest/gallery/personal-development.jpg'
import play from '../../assest/gallery/play.jpg'
import { Link } from 'react-router-dom'

const Facilities = () => {
    return (
        <div className="gallery-section">
            <div className="topper container">

                <div className="topper-awards-heading">
                    <div className="topper-awards-icon">
                        <i class="fa-regular fa-handshake"></i>
                    </div>
                    <h3>LIFE AT VIJAY CONVENT</h3>
                </div>

                <div className="gallery">
                    <div className="gallery__item gallery__item--1">
                        <Link to={undefined} className="gallery__link">
                            <img src={learn} className="gallery__image" />
                            <div className="gallery__overlay">
                                <div className='span-div'>
                                    <div className="header">
                                        <h4>Concept Based Learning</h4>
                                    </div>
                                    <div className="content">
                                        Content just focuses on facts while concept focuses on making sense of those facts and the world around us.
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="gallery__item gallery__item--2">
                        <Link to={undefined} className="gallery__link">
                            <img src={activity} className="gallery__image" />
                            <div className="gallery__overlay">
                                <div className='span-div'>
                                    <div className="header">
                                        <h4>Activity</h4>
                                    </div>
                                    <div className="content">
                                        Co-curricular activities are an essential part of school life and helps in enhancing learning process of students at school.
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="gallery__item gallery__item--3">
                        <Link to={undefined} className="gallery__link">
                            <img src={play} className="gallery__image" />
                            <div className="gallery__overlay">
                                <div className='span-div'>
                                    <div className="header">
                                        <h4>Play</h4>
                                    </div>
                                    <div className="content">
                                        We have our own playground for cricket and other sports which provide students better opportunity.
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="gallery__item gallery__item--4">
                        <Link to={undefined} className="gallery__link">
                            <img src={personalDevelopment} className="gallery__image" />
                            <div className="gallery__overlay">
                                <div className='span-div'>
                                    <div className="header">
                                        <h4>Personal Development</h4>
                                    </div>
                                    <div className="content">
                                        VJ Convent havean important role to play in helping learners develop and manage their physical and emotional well-being.
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="gallery__item gallery__item--5">
                        <Link to={undefined} className="gallery__link">
                            <img src={digitalClassroom} className="gallery__image" />
                            <div className="gallery__overlay">
                                <div className='span-div'>
                                    <div className="header">
                                        <h4>Digital Classroom</h4>
                                    </div>
                                    <div className="content">
                                        Digital classrooms prepare students for life in school and the real world by providing them with a technology-based education.
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Facilities
