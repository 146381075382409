import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { useParams } from 'react-router-dom'
import '../stylings/singleGallery.css'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { hideLoading, showLoading } from '../redux store/loader'

const SingleGalleryAlbum = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const param = useParams();
    const galleryId = parseInt(param.galleryId)

    const { photoGallery } = useSelector((state) => state.photoGallery)

    const filterGalleryData = photoGallery.filter(galleryItem => galleryItem.id === galleryId);

    const formattedUpdateDate = moment(filterGalleryData[0]?.publisheddate, 'DD-MMM-YYYY hh:mm A').format('DD-MMM-YYYY');

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(filterGalleryData[0].attachments.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === filterGalleryData[0].attachments.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    return (
        <Layout>
            <div className='singlegallery'>

                {openModal &&
                    <div className='sliderWrap'>
                        <i className="fa-solid fa-xmark btnClose" onClick={handleCloseModal}></i>
                        <i className="fa-solid fa-chevron-left btnPrev" onClick={prevSlide}></i>
                        <i className="fa-solid fa-chevron-right btnNext" onClick={nextSlide}></i>
                        <div className='fullScreenImage'>
                            <img src={filterGalleryData[0].attachments[slideNumber].path} alt='' />
                        </div>
                    </div>
                }
                <div className="singlegallery-tiles container">
                    <div className="galleryheader">
                        <h3>photo gallery</h3>
                    </div>
                    <div className="gallery-container">
                        <div className="description">
                            <div className="sub-title">
                                {filterGalleryData[0]?.title}
                            </div>
                            <div className="sub-body">
                                <div className="box">
                                    <span><strong>Event Date:</strong> {moment(filterGalleryData[0].eventdate).format("DD-MMM-YYYY")}</span>
                                </div>
                                <div className="box">
                                    <span><strong>Updated On:</strong> {formattedUpdateDate} </span>
                                </div>
                                <div className="box">
                                    <span><strong>Total Photos:</strong> {filterGalleryData[0]?.attachments.length} </span>
                                </div>
                            </div>
                            <div className="body">
                                <span>Description:</span> {filterGalleryData[0]?.body}
                            </div>
                            <div className='galleryWrap'>
                                {
                                    filterGalleryData.map((item) => (
                                        item.attachments.map((attachmentItem, index) => (
                                            <div
                                                className='single'
                                                key={index}
                                                onClick={() => handleOpenModal(index)}
                                            >
                                                <img src={attachmentItem.path} alt='' />
                                            </div>
                                        ))
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default SingleGalleryAlbum
