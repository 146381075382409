import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setNotices } from './redux store/notice';
import { useLayoutEffect, useState } from 'react';
import { setAudioNotices } from './redux store/audioNotice';
import { setPhotoGallery } from './redux store/photoGallery';
import Home from './pages/Home';
import Loader from './components/Loader';
import PublicDisclosure from './pages/PublicDisclosure';
import Login from './pages/Login';
import ContactUs from './pages/ContactUs';
import Syllabus from './pages/Syllabus';
import TimeTable from './pages/TimeTable';
import AcademicCalender from './pages/AcademicCalender';
import axios from 'axios';
import SingleNotice from './pages/SingleNotice';
import NormalNoticeAll from './pages/NormalNoticeAll';
import AudioNoticesAll from './pages/AudioNoticesAll';
import SingleAudioNotice from './pages/SingleAudioNotice';
import ScrollToTop from './components/ScrollToTop';
import SingleGalleryAlbum from './pages/SingleGalleryAlbum';
import GalleryAll from './pages/GalleryAll';
import VideoGalleryAll from './pages/VideoGalleryAll';
import AboutSchool from './pages/AboutSchool';
import PrincipalMessage from './pages/PrincipalMessage';
import ChairmanMessage from './pages/ChairmanMessage';
import ManagerMessage from './pages/ManagerMessage';
import { setVideoGallery } from './redux store/videoGallery';
import SingleVideoGallery from './pages/SingleVideoGallery';
import CbseMandatoryDisclosure from './pages/CbseMandatoryDisclosure';

function App() {

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loader)
  const [loadNotice, setLoadNotice] = useState(false)
  const [audioLoadNotice, setAudioLoadNotice] = useState(false)

  const getNotices = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_NOTICE_API)
      if (response.data) {
        dispatch(setNotices(response.data))
        setLoadNotice(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAudioNotices = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_NOTICE_AUDIO_API)
      if (response.data) {
        dispatch(setAudioNotices(response.data))
        setAudioLoadNotice(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPhotoGallery = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_GALLERY_API)
      if (response.data) {
        dispatch(setPhotoGallery(response.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getVideoGallery = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_VIDEO_GALLERY_API)
      if (response.data) {
        dispatch(setVideoGallery(response.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useLayoutEffect(() => {
    getNotices()
    getAudioNotices()
    getPhotoGallery()
    getVideoGallery()
  }, [])

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route exact path='/' element={<Home loadNotice={loadNotice} audioLoadNotice={audioLoadNotice} />} />
        <Route exact path='/public-disclosure' element={<PublicDisclosure />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/contact' element={<ContactUs />} />
        <Route exact path='/syllabus' element={<Syllabus />} />
        <Route exact path='/time-table' element={<TimeTable />} />
        <Route exact path='/academic-calender' element={<AcademicCalender />} />
        <Route exact path='/notice/:noticeId' element={<SingleNotice />} />
        <Route exact path='/audioNotice/:noticeId' element={<SingleAudioNotice />} />
        <Route exact path='/noticeall' element={<NormalNoticeAll />} />
        <Route exact path='/audionoticeall' element={<AudioNoticesAll />} />
        <Route exact path='/gallery/:galleryId' element={<SingleGalleryAlbum />} />
        <Route exact path='/video/:videoId' element={<SingleVideoGallery />} />
        <Route exact path='/galleryall' element={<GalleryAll />} />
        <Route exact path='//videoall' element={<VideoGalleryAll />} />
        <Route exact path='/about-school' element={<AboutSchool />} />
        <Route exact path='/principal-message' element={<PrincipalMessage />} />
        <Route exact path='/chairman-message' element={<ChairmanMessage />} />
        <Route exact path='/manager-message' element={<ManagerMessage />} />
        <Route exact path='/cbse-mandatory-public-disclosure' element={<CbseMandatoryDisclosure />} />
      </Routes>
      <ScrollToTop />
    </>
  );
}

export default App;
