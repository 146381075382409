import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import "../stylings/aboutSchool.css"
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';

const AboutSchool = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    return (
        <Layout>
            <div className="about-school container">
                <div className="about-school-wrapper">
                    <div className="about-school-header">
                        <h3>about school</h3>
                    </div>
                    <div className="sub-heading">
                        <h5>welcome to vidya jain convent school!</h5>
                    </div>
                    <div className="description">
                        <p>
                            At <span>vidya jain convent school</span>, we believe in nurturing young minds to their fullest potential, fostering a love for learning, and providing a dynamic environment where students can thrive academically, socially, and emotionally. Our commitment to excellence in education is reflected in our comprehensive curriculum, dedicated faculty, and vibrant school community.
                        </p>
                    </div>
                    <div className="sub-heading">
                        <h5>Academic Excellence:</h5>
                    </div>
                    <div className="description">
                        <p>
                            Our curriculum is designed to inspire curiosity, critical thinking, and creativity. We offer a well-rounded education that encompasses core subjects such as Mathematics, Science, Language Arts, Social Studies as well as enrichment programs including Art, Music, Physical Education, and Technology. With a focus on personalized learning, we cater to the diverse needs and learning styles of every student, ensuring that each individual receives the support and resources they need to succeed.
                        </p>
                        <p>
                            At <span>vidya jain convent school</span>, we recognize the importance of preparing students for the challenges of the 21st century. That's why we integrate technology into the learning process, equipping students with essential digital skills and empowering them to adapt to an ever-changing world. Through project-based learning, collaborative activities, and real-world applications, students develop the critical skills of communication, collaboration, problem-solving, and creativity that are essential for success in today's global society.
                        </p>
                    </div>
                    <div className="sub-heading">
                        <h5>Dedicated Faculty:</h5>
                    </div>
                    <div className="description">
                        <p>
                            Our faculty members are passionate educators who are dedicated to the success and well-being of every student. With their expertise, enthusiasm, and commitment to excellence, they create engaging and supportive learning environments where students feel valued, challenged, and inspired to reach their full potential. Our teachers foster a culture of respect, empathy, and inclusivity, promoting positive relationships and a sense of belonging within the school community.
                        </p>
                    </div>
                    <div className="sub-heading">
                        <h5>Holistic Development:</h5>
                    </div>
                    <div className="description">
                        <p>
                            At <span>vidya jain convent school</span>, we believe in nurturing the whole child - academically, socially, emotionally, and morally. In addition to our rigorous academic program, we offer a variety of extracurricular activities, clubs, and sports programs that provide students with opportunities for personal growth, leadership development, and community engagement. Whether it's participating in a school play, joining a robotics team, or volunteering for a local charity, students are encouraged to explore their interests, develop their talents, and make meaningful contributions to society.
                        </p>
                    </div>
                    <div className="sub-heading">
                        <h5>Supportive Community:</h5>
                    </div>
                    <div className="description">
                        <p>
                            Our school community is built on a foundation of collaboration, respect, and mutual support. We believe that strong partnerships between students, parents, teachers, and staff are essential for student success. Through open communication, parent involvement, and community events, we foster strong connections and create a supportive network that empowers students to thrive academically and socially.
                        </p>
                    </div>
                    <div className="sub-heading">
                        <h5>Preparing for the Future:</h5>
                    </div>
                    <div className="description">
                        <p>
                            At <span>vidya jain convent school</span>, we are committed to preparing students not only for success in their academic pursuits but also for success in life. We instill in our students the values of integrity, responsibility, resilience, and empathy, preparing them to be compassionate and ethical leaders who make positive contributions to their communities. Whether they pursue higher education, enter the workforce, or pursue other pathways, our graduates are well-equipped with the knowledge, skills, and character traits needed to excel in whatever they choose to pursue.
                        </p>
                        <p>
                            In conclusion, <span>vidya jain convent school</span> is more than just a place of learning – it's a vibrant and inclusive community where students are empowered to discover their passions, unlock their potential, and become lifelong learners. We invite you to join us on this exciting journey of growth, discovery, and achievement.
                        </p>
                        <p>Welcome to <span>vidya jain convent school</span> - where every child has the opportunity to shine!</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutSchool
