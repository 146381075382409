import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { loaderSlice } from "./loader";
import { noticeSlice } from "./notice";
import { audioNoticeSlice } from "./audioNotice";
import { photoGallerySlice } from "./photoGallery";
import { videoGallerySlice } from "./videoGallery";


const rootReducer = combineReducers({
    loader: loaderSlice.reducer,
    notices: noticeSlice.reducer,
    audioNotices: audioNoticeSlice.reducer,
    photoGallery: photoGallerySlice.reducer,
    videoGallery: videoGallerySlice.reducer,
});

const store = configureStore({
    reducer: rootReducer,
});
export default store