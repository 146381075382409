import React from 'react'
import "../../stylings/message.css"
import chairmanPhoto from "../../assest/chairmanPhoto.jpg"
import principalPhoto from "../../assest/principalPhoto.jpg"

const Message = () => {

    return (
        <div className="message container">
            <div className="message_wrap">
                <ul className="row unorderList">
                    <li className="cols-detail col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div className="message-list">
                            <div className="item-inner">
                                <div className="profile-icon"> <img src={chairmanPhoto} alt="" /> </div>
                                <div className="profile-title">
                                    <h2>Chairperson </h2>
                                    <p>Welcome to Vidya Jain Convent School!  We endeavor to show a better capacity than cultivate greatness in instruction and add to the ceaseless improvement of understudy learning and the school climate by giving information on proof based proficiency techniques and assets to all partners.
                                    </p>
                                    <p> We look forward to the opportunity of working with you and your child as we make a difference in the lives of our children. Our focus this year will be on implementing a strong and challenging academic foundation while providing the highest quality education, creating a school where our students feel safe and empowered to learn.
                                    </p>
                                </div>
                            </div>
                            <div className="profile-name">
                                Mrs Vidya Jain, having 35 years experience in previous school
                            </div>
                        </div>
                    </li>

                    <li className="cols-detail col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div className="message-list">
                            <div className="item-inner">
                                <div className="profile-icon"><img src={principalPhoto} alt="" /></div>
                                <div className="profile-title">
                                    <h2>Principal</h2>
                                    <p>
                                        We are proud of our school and invite you to explore our website to learn more about our school. We encourage you to take advantage of the information on our site. We collaborative coalition of dedicated educators who work relentlessly to provide our students with an educational experience that reflects rigor, academic excellence, and creativity. We are a community of educators engaged in a continuous process of academic improvement for all of our students and ourselves.
                                    </p>
                                    <p>
                                        We encourage our students to embrace diversity and respect cultural differences and traditions.  We pride ourselves on maintaining a climate of academic excellence which challenges, encourages and supports all students in reaching both their academic and emotional potential.  Our primary focus is on facilitating the education of the whole child and fostering a well-rounded student who has integrity and a natural sense of self-worth.
                                    </p>
                                </div>
                            </div>
                            <div className="profile-name">
                                Naresh Kumar Jain, having 16 years experience in previous school
                            </div>
                        </div>
                    </li>



                </ul>
            </div>
        </div>
    )
}

export default Message
