import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import "../stylings/contact.css"
import { useDispatch } from 'react-redux'
import { hideLoading, showLoading } from '../redux store/loader'

const ContactUs = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const [selected, setSelected] = useState(0)

    const data = [
        {
            id: 1,
            question: 'contact information',
            answer: <div className="content-row row">
                <div className="col-container contact-content col-lg-12">
                    <div className="content-container">
                        <div className="heading">
                            <h6>Phone Number</h6>
                        </div>
                        <div className="information">
                            <p>9999740333</p>
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="heading">
                            <h6>Email</h6>
                        </div>
                        <div className="information">
                            <p>vjconvent2004@gmail.com</p>
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="heading">
                            <h6>Address</h6>
                        </div>
                        <div className="information">
                            <p>Khasra No.- 142, Plot - 289 , Delhi - 110081</p>
                        </div>
                    </div>
                </div>
                <div className="col-container col-lg-12">
                    <div className="img-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55976.08036316858!2d77.016338!3d28.734335!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d07d161ca9ba1%3A0x31f5fa9d1ce42c61!2sVidya%20Jain%20Convent%20School!5e0!3m2!1sen!2sin!4v1710967789836!5m2!1sen!2sin"
                            style={{
                                style: "border:0;", allowfullscreen: "", loading: "lazy",
                                referrerpolicy: "no-referrer-when-downgrade"
                            }} className='google-map'></iframe>
                    </div>
                </div>
            </div>
        }
    ]

    const toggle = (index) => {
        if (selected == index) {
            return setSelected(null)
        }

        setSelected(index)
    }

    return (
        <Layout>
            <div className="contactus container">
                <div className="wrapper">
                    <div className="accordion">
                        {data.map((item, index) => (
                            <div className="item" key={item.id}>
                                <div className="title" onClick={() => toggle(index)}>
                                    <h5>{item.question}</h5>
                                </div>
                                <div className={selected === index ? 'content show' : 'content'}>
                                    {item.answer}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ContactUs
