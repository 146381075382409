import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';
import { Link, useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import moment from 'moment';
import '../stylings/singleGallery.css'

const SingleVideoGallery = () => {

    const { videoGallery } = useSelector((state) => state.videoGallery)
    const param = useParams();
    const videoId = parseInt(param.videoId)

    const filterVideoData = videoGallery.filter(galleryItem => galleryItem.id === videoId);
    const formattedUpdateDate = moment(filterVideoData[0]?.publisheddate, 'DD-MMM-YYYY hh:mm A').format('DD-MMM-YYYY');

    const embedVideoUrl = (url) => {
        return url?.replace("https://youtu.be", "https://www.youtube.com/embed");
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])


    return (
        <Layout>
            <div className="singlegallery single-video-gallery pb-4">
                <div className="singlegallery-tiles container videogallery-tiles">
                    <div className="galleryheader">
                        <h3>video gallery</h3>
                    </div>
                    <div className="gallery-container">
                        <div className="description">
                            <div className="sub-title">
                                {filterVideoData[0]?.title}
                            </div>
                            <div className="sub-body">
                                <div className="box">
                                    <span><strong>Event Date:</strong> {moment(filterVideoData[0]?.eventdate).format("DD-MMM-YYYY")}</span>
                                </div>
                                <div className="box">
                                    <span><strong>Updated On:</strong> {formattedUpdateDate} </span>
                                </div>
                                <div className="box">
                                    <span><strong>Total Photos:</strong> {filterVideoData[0]?.attachments.length} </span>
                                </div>
                            </div>
                            <div className="body">
                                <span>Description:</span> {filterVideoData[0]?.body}
                            </div>
                            <div className="galleryWrap">
                                {filterVideoData.map((item) => (
                                    item.attachments.map((attachmentItems) => (
                                        <div className='video-item' key={attachmentItems.id}>
                                            <iframe src={embedVideoUrl(attachmentItems.path)} frameBorder={0} allowFullScreen={true} allowtransparency="true">
                                            </iframe>
                                            <div className="description">
                                                <div className="title">
                                                    <span>Title: </span>{attachmentItems.name}
                                                </div>
                                                <div className="published">
                                                    <span>Published: </span>
                                                    {moment(item.eventdate).format("DD-MMM-YYYY")}
                                                </div>
                                                <div className="btn-container">
                                                    <Link to={attachmentItems.path} target='_blank' > VIEW VIDEO </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SingleVideoGallery
