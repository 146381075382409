import { createSlice } from "@reduxjs/toolkit";


export const audioNoticeSlice = createSlice({
    name: 'audioNotices',
    initialState: {
        audioNotices: []
    },
    reducers: {
        setAudioNotices: (state, action) => {
            state.audioNotices = action.payload;
        }
    },
});

export const {setAudioNotices} = audioNoticeSlice.actions;