import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';
import '../stylings/messagesPage.css'
import principalImage from '../assest/principalPhoto.jpg'

const PrincipalMessage = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showLoading());
    setTimeout(() => {
      dispatch(hideLoading())
    }, [1700])
  }, [])

  return (
    <Layout>
      <div className="message-container container">
        <div className="message-wrapper">
          <div className="message-wrapper-header">
            <h3>From The Desk Of Principal</h3>
          </div>
          <div className="body-wrapper">
            <div className="img-container">
              <img src={principalImage} alt="principal" />
            </div>
            <div className="content">
              <p className="quote">
                "The object of education is to prepare the young to educate themselves throughout their lives - ROBERT HUTCHINS"
              </p>
              <p>
                It gives me immense pleasure to welcome you to the website of <span>M. R. Bharti Model Sr. Sec. School</span> it was conceived decades ago with a dream to create a beacon for youth the kings and queens, who are morally upright, socially responsible and vibrant human beings. We believe that in this global scenario, learning has to go beyond the confines of a classroom having a wider spectrum and building a world while values and principles flourish.
              </p>
              <p>
                We believe that each child is a unique creation of God and at <span>M. R. Bharti Model Sr. Sec. School</span>; we provide a platform to the children to unravel their own strength and interest. "<span>I can, I shall, I will</span>, is the punch line for every students, as they consider every pedestal a stepping stone to success, thus making their Alma Mater proud today and forever. I extend my gratitude to all the parents for the trust placed in us and we look forward to their constant support & guidance in all over endeavors. As we complete two decades of impact quality education at <span>M. R. Bharti Model Sr. Sec. School</span>, we seek the blessing of the Lord on the entire <span>M. R. Bharti Model Sr. Sec. School</span> Family.
              </p>
              <div className="signature">
                <p className="name">
                  Mrs. Anisha Devi
                </p>
                <p className="designation">
                  Principal, M.R. Bharti Model Sr. Sec. School
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrincipalMessage
