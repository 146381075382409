import React, { useEffect} from 'react'
import Layout from '../components/Layout'
import CarouselSection from '../components/HomePage/CarouselSection'
import Notices from '../components/HomePage/Notices'
import AdmissionNotice from '../components/HomePage/AdmissionNotice'
import SchoolAwards from '../components/HomePage/SchoolAwards'
import { useDispatch } from 'react-redux'
import { hideLoading, showLoading } from '../redux store/loader'
import Message from '../components/HomePage/Message'
import Gallery from '../components/HomePage/Gallery'
import Facilities from '../components/HomePage/Facilities'
import Topper from '../components/HomePage/Topper'
import VideoGallery from '../components/HomePage/VideoGallery'

const Home = ({loadNotice, audioLoadNotice}) => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    return (
        <Layout>
            <CarouselSection />
            <AdmissionNotice />
            <Message />
            <Notices loadNotice={loadNotice} audioLoadNotice={audioLoadNotice} />
            <Facilities />
            <Topper />
            <SchoolAwards />
            <Gallery />
            <VideoGallery />
        </Layout>
    )
}

export default Home
