import React from 'react'
import '../stylings/newsMarquee.css'
import Marquee from 'react-fast-marquee'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import newGif from '../assest/newGif.gif'

const NewsMarquee = () => {
    const { notices } = useSelector((state) => state.notices)

    return (
        <div className="marquee-container sticky-top1">
            <div className="marquee-content-container">
                <div className="news-title">
                    <Marquee pauseOnHover={true}>
                        <p>
                            {notices.map((notice, index) => index < 2 && (
                                <div className='news-container'>
                                    <i className="fa-regular fa-hand-point-right"></i>
                                    <Link key={notice.id} to={`/notice/${notice.id}`}>{notice.subject}</Link>
                                    <div className="img-container">
                                        <img src={newGif} alt="news-new-gif" />
                                    </div>
                                </div>
                            ))}
                        </p>
                    </Marquee>
                </div>
            </div>
        </div>
    )
}

export default NewsMarquee
