import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import "../stylings/galleryAll.css"
import { hideLoading, showLoading } from '../redux store/loader'
import moment from 'moment'

const GalleryAll = () => {

    // const [yearSelected, setYearSelected] = useState(null)

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    const { photoGallery } = useSelector((state) => state.photoGallery)
    let formattedUpdateDate

    // let buttonYear

    // // Filtered Photo Gallery According to Selected Year
    // const filteredData = photoGallery.filter(photoGalleryItem => {
    //     const year = moment(photoGalleryItem.creationdatestring, 'DD-MMM-YYYY hh:mm A').year();
    //     return year === Number(yearSelected);
    // });

    // // Eliminating the Repeated Years and Rendering the Year Selection Button
    // const filterRepeatedValues = (syllabus) => {
    //     const filterClassName = [];
    //     console.log(filterClassName)
    //     return syllabus.filter(syllabusItem => {
    //         const year = moment(syllabusItem.creationdatestring, 'DD-MMM-YYYY hh:mm A').year();
    //         if (!filterClassName.includes(year)) {
    //             filterClassName.push(year);
    //             return true;
    //         }
    //         return false;
    //     });
    // };

    return (
        <Layout>
            <div className="singlegallery-tiles galleryall-tiles container">
                <div className="galleryheader">
                    <h3>all photo gallery</h3>
                </div>
                {/* {
                    filterRepeatedValues(photoGallery).map((syllabusItem, index) => (
                        buttonYear = moment(syllabusItem.creationdatestring, 'DD-MMM-YYYY hh:mm A').year(),
                        <input type="button" value={buttonYear} onClick={(e) => { setYearSelected(e.target.value) }} />
                    ))
                } */}
                <div className="gallery-container">
                    <div className="description">
                        <div className='galleryWrap'>
                            {photoGallery.map((galleryItem) => (
                                <div className="single" key={galleryItem.id}>
                                    {galleryItem.attachments.map((galleryAttachment, index) => index < 1 && (
                                        <Link to={`/gallery/${galleryAttachment.attachforid}`} key={galleryAttachment.id}>
                                            <img src={galleryAttachment.path} alt="" />
                                            <div className="description">
                                                <div className="title">
                                                    <span>Title: </span>{galleryItem.title}
                                                </div>
                                                <div className="published">
                                                    <span>Event Date: </span>
                                                    {moment(galleryItem.eventdate).format("DD-MMM-YYYY")}
                                                </div>
                                                <div className="btn-container">
                                                    <Link to={`/gallery/${galleryAttachment.attachforid}`} > VIEW GALLERY </Link>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default GalleryAll
