import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoading, showLoading } from '../redux store/loader'

const VideoGalleryAll = () => {

    const { videoGallery } = useSelector((state) => state.videoGallery)
    console.log(videoGallery)

    const embedVideoUrl = (url) => {
        return url.replace("https://youtu.be", "https://www.youtube.com/embed");
    };

    let eventDate

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    return (
        <Layout>
            <div className="singlegallery-tiles galleryall-tiles videogallery-tiles container">
                <div className="galleryheader">
                    <h3>all video gallery</h3>
                </div>
                <div className="gallery-container">
                    <div className="description">
                        <div className='galleryWrap'>
                            {videoGallery.map((galleryItem) => (
                                <div className="video-container" key={galleryItem.id}>
                                    {galleryItem.attachments.map((galleryAttachment, index) => index < 1 && (
                                        <div className='video-item' key={galleryAttachment.id}>
                                            <iframe src={embedVideoUrl(galleryAttachment.path)} frameBorder={0} allowFullScreen={true} allowtransparency="true">
                                            </iframe>
                                            <div className="description">
                                                <div className="title">
                                                    <span>Title: </span>{galleryItem.title}
                                                </div>
                                                <div className="published">
                                                    <span>Event Date: </span>
                                                    {moment(galleryItem.eventdate).format("DD-MMM-YYYY")}
                                                </div>
                                                <div className="btn-container">
                                                    <Link to={`/video/${galleryItem.id}`}> VIEW VIDEO GALLERY </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default VideoGalleryAll
