import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../redux store/loader';
import Layout from '../components/Layout';
import cbse from '../assest/CBSE Mandatory Disclosure/cbse.pdf'
import counselor from '../assest/CBSE Mandatory Disclosure/counselor.pdf'
import epf1 from '../assest/CBSE Mandatory Disclosure/epf1.pdf'
import epf2 from '../assest/CBSE Mandatory Disclosure/epf2.pdf'
import epf3 from '../assest/CBSE Mandatory Disclosure/epf3.pdf'
import landCertificate from '../assest/CBSE Mandatory Disclosure/land_certificate.pdf'
import recognitionLetterCertificate from '../assest/CBSE Mandatory Disclosure/recognition_letter.pdf'
import computerLab from '../assest/CBSE Mandatory Disclosure/computer-lab.jpg'
import mathLab from '../assest/CBSE Mandatory Disclosure/math-lab.jpg'
import scienceLab from '../assest/CBSE Mandatory Disclosure/science-lab.jpg'
import library from '../assest/CBSE Mandatory Disclosure/library.jpg'
import ramp1 from '../assest/CBSE Mandatory Disclosure/ramp-1.jpg'
import ramp2 from '../assest/CBSE Mandatory Disclosure/ramp-2.jpg'
import ramp3 from '../assest/CBSE Mandatory Disclosure/ramp-3.jpg'
import toilet1 from '../assest/CBSE Mandatory Disclosure/toilet-1.jpg'
import toilet2 from '../assest/CBSE Mandatory Disclosure/toilet-2.jpg'
import toilet3 from '../assest/CBSE Mandatory Disclosure/toilet-3.jpg'
import { Link } from 'react-router-dom';

const CbseMandatoryDisclosure = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showLoading());
        setTimeout(() => {
            dispatch(hideLoading())
        }, [1700])
    }, [])

    return (
        <Layout>
            <div className="public-disclosure container">
                <div className="public-disclosure-header">
                    <h3>CBSE mandatory public disclosure</h3>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th scope="row">name of the school</th>
                                <td>Vidya Jain Convent School</td>
                            </tr>
                            <tr>
                                <th scope="row">address</th>
                                <td>Khasra No.- 142, Plot - 289 , Delhi - 110081</td>
                            </tr>
                            <tr>
                                <th scope="row">principal name & qualification</th>
                                <td>Mr. Naresh Kumar Jain</td>
                            </tr>
                            <tr>
                                <th scope="row">school e-mail</th>
                                <td className='email-td'>vjconvent2004@gmail.com</td>
                            </tr>
                            <tr>
                                <th scope="row">contact details</th>
                                <td>9999740333</td>
                            </tr>
                            <tr>
                                <th scope="row">land certificate</th>
                                <td><Link to={`/${landCertificate}`} target="_blank" rel="noopener noreferrer"> land certificate <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                            <tr>
                                <th scope="row">counselor certificate</th>
                                <td><Link to={`/${counselor}`} target="_blank" rel="noopener noreferrer"> counselor certificate <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                            <tr>
                                <th scope="row">recognition letter</th>
                                <td><Link to={`/${recognitionLetterCertificate}`} target="_blank" rel="noopener noreferrer"> recognition letter <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                            <tr>
                                <th scope="row">CBSE</th>
                                <td><Link to={`/${cbse}`} target="_blank" rel="noopener noreferrer"> CBSE <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                            <tr>
                                <th scope="row">EPF</th>
                                <td>
                                    <Link to={`/${epf1}`} target="_blank" rel="noopener noreferrer"> EPF 1 <i className="fa-solid fa-download"></i></Link>
                                    <Link to={`/${epf2}`} target="_blank" rel="noopener noreferrer"> EPF 2 <i className="fa-solid fa-download"></i></Link>
                                    <Link to={`/${epf3}`} target="_blank" rel="noopener noreferrer"> EPF 3 <i className="fa-solid fa-download"></i></Link>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">ramp</th>
                                <td>
                                    <Link to={`/${ramp1}`} target="_blank" rel="noopener noreferrer"> view image 1 <i className="fa-solid fa-download"></i></Link>
                                    <Link to={`/${ramp2}`} target="_blank" rel="noopener noreferrer"> view image 2 <i className="fa-solid fa-download"></i></Link>
                                    <Link to={`/${ramp3}`} target="_blank" rel="noopener noreferrer"> view image 3 <i className="fa-solid fa-download"></i></Link>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">CWSN toilet</th>
                                <td>
                                    <Link to={`/${toilet1}`} target="_blank" rel="noopener noreferrer"> view image 1 <i className="fa-solid fa-download"></i></Link>
                                    <Link to={`/${toilet2}`} target="_blank" rel="noopener noreferrer"> view image 2 <i className="fa-solid fa-download"></i></Link>
                                    <Link to={`/${toilet3}`} target="_blank" rel="noopener noreferrer"> view image 3 <i className="fa-solid fa-download"></i></Link>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">science lab</th>
                                <td><Link to={`/${scienceLab}`} target="_blank" rel="noopener noreferrer"> science lab <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                            <tr>
                                <th scope="row">math lab</th>
                                <td><Link to={`/${mathLab}`} target="_blank" rel="noopener noreferrer"> math lab <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                            <tr>
                                <th scope="row">computer lab</th>
                                <td><Link to={`/${computerLab}`} target="_blank" rel="noopener noreferrer"> computer lab <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                            <tr>
                                <th scope="row">library</th>
                                <td><Link to={`/${library}`} target="_blank" rel="noopener noreferrer"> library <i className="fa-solid fa-download"></i></Link></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    )
}

export default CbseMandatoryDisclosure
